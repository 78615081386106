<template>
  <div>
    <div class="d-flex align-items-center mb-5">
      <h1 class="mr-4">Statistics</h1>
      <!-- <ul class="nav nav-tabs flex-grow-1">
        <li class="nav-item">
          <router-link
            :to="{ name: 'statistics', params: { period: 'monthly' } }"
            class="nav-link"
            :class="{ active: period === 'monthly' }"
          >
            Monthly
          </router-link>
        </li>
        <li class="nav-item">
          <router-link
            :to="{ name: 'statistics', params: { period: 'weekly' } }"
            class="nav-link"
            :class="{ active: period === 'weekly' }"
          >
            Weekly
          </router-link>
        </li>
      </ul> -->
    </div>
    <!-- <StatisticChart v-if="statistics" :statistics="statistics" /> -->
    <h2>Delivery Speed</h2>
    <ShippingStatisticChart v-if="shippingStatistics" :shippingStatistics="shippingStatistics" class="mt-5" />
    <p v-else>Loading statistics...</p>
  </div>
</template>

<script>
import { db } from '@/shared/firebase'
// import StatisticChart from '@/components/StatisticChart'
import ShippingStatisticChart from '@/components/ShippingStatisticChart'

export default {
  components: { ShippingStatisticChart },
  data() {
    return {
      statistics: null,
      shippingStatistics: null
    }
  },
  computed: {
    period() {
      return this.$route.params.period || 'monthly'
    }
  },
  watch: {
    period() {
      this.getStatistics()
    }
  },
  created() {
    this.getStatistics()
    this.getShippingStatistics()
  },
  methods: {
    getStatistics() {
      db.collection(`private/statistics/${this.period}SalesReports`)
        .get()
        .then(snapshot => {
          const statistics = snapshot.docs.map(doc => {
            return { ...doc.data(), id: doc.id }
          })
          this.statistics = statistics
        })
    },
    getShippingStatistics() {
      db.collection(`private/statistics/shippingSpeed`)
        .orderBy('date', 'desc')
        .limit(30)
        .get()
        .then(snapshot => {
          this.shippingStatistics = snapshot.docs.map(doc => doc.data())
        })
    }
  }
}
</script>
