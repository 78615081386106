<template>
  <div>
    <highcharts class="chart" :options="chart"></highcharts>
  </div>
</template>

<script>
export default {
  props: {
    shippingStatistics: {
      type: Array,
      required: true
    }
  },
  computed: {
    chart() {
      const chart = {
        chart: { type: 'column' },
        title: { text: '', style: { display: 'none' } },
        xAxis: { type: 'datetime', dateTimeLabelFormats: { day: '%e. %b' } },
        yAxis: { reversedStacks: false, min: 0, title: { text: 'Number of orders' } },
        plotOptions: { column: { stacking: 'normal' } },
        credits: { enabled: false },
        series: [
          { name: '0 days', data: [], color: '#92c037' },
          { name: '1 day', data: [], color: '#75bd35' },
          { name: '2 days', data: [], color: '#d1bf33' },
          { name: '3 days', data: [], color: '#d38c33' },
          { name: '4 days', data: [], color: '#d95e38' },
          { name: '5+ days', data: [], color: '#FF0000' }
        ]
      }

      this.shippingStatistics.forEach(statistic => {
        Object.keys(statistic.shipmentsByDays).forEach(key => {
          chart.series[key].data.push([new Date(statistic.date).getTime(), statistic.shipmentsByDays[key]])
        })
      })

      return chart
    }
  }
}
</script>
